a:hover ,input,input:focus,input:active,textarea,textarea:focus,textarea:active{
    border: none ;
    outline: none ;
}
*{
    font-family: 'cairo';
}








main[data-theme="dark"] {
    --primary: rgb(2, 164, 149);
    --primary-transparent: rgba(2, 164, 149, 0.8);
    --primary-dark: rgba(2, 164, 149, 1);
    --secondary: rgba(25, 50, 75, 1);
    --secondary-transparent: rgba(25, 50, 75, .8);
    --blue: #43219b;
    --dark: rgba(25, 25, 35, 1);
    --dark-transparent: rgba(25, 25, 35, .8);
    --white: #ffffff;
    --transparent: transparent;
    --orang: rgb(187, 145, 33);
    --orang-dark: #6c4f26;
    --yelow:#FFFF00

}

input.input,textarea.input,.input-form,.textarea-form{
    color: var(--yelow) !important;
}


.bg-color{
    background-color: var(--primary-dark);
}
.nav-color{
    background-color: var(--dark-transparent);
}
/*  Global classes */
.bg-gradient-main {
    background: linear-gradient(to right, var(--secondary), var(--primary-transparent), var(--dark)) no-repeat fixed;
    background-size: cover;
    background-blend-mode: soft-light, exclusion;
}

.bg-gradient-main-img {
    background: linear-gradient(to right, var(--secondary), var(--primary-transparent), var(--dark)) no-repeat fixed;
    background-size: cover;

    position: relative;
}

.online-img{
    width: min(100%,100px);
    aspect-ratio: 1/1;
}


.bg-transparent {
    background: var(--transparent);
}

.border-botom-white {
    border-bottom: 1px solid var(--white);
}

.border-top-white {
    border-top: 1px solid var(--white);
}
.border-orange{
    border-color: var(--orang);
}
.max-width-50{
    width: min(50%,125px) !important;
}
.nav-h {
    height: 65px !important;
}

.wrapper {
    padding-top: 80px;
    max-width: 1300px;
    margin: auto;
}

.product-item {
    height: 322px;
    padding: 10px;
    border-radius: 13px;
    overflow: hidden;
    background: linear-gradient(to bottom, var(--orang) , var(--orang-dark)20%, var(--dark-transparent));
    transition: all 1s;
}

.product-item:hover {
    background: linear-gradient(to bottom, var(--orang-dark), var(--dark-transparent));
}

.product-img {
    width: min(100%,165px);
    display: block;
    margin: auto ;
    height: 165px;
    border-radius: 13px;
    position: relative;
    z-index: 2;
}
.container-img{
    width: 100%;
    height: 165px !important;
    position: relative;
}

.footer {
    height: 60px;
}

.facebook-icon {
    color: cornflowerblue;
}

.telegram-icon {
    color: darkcyan;
}

.instagram-icon {
    color: chocolate;
}

.whatsapp-icon {
    color: darkgreen;
}

.carousel-item {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    aspect-ratio: 7/2;
    position: relative;

}
/* carousel */
.container-slider {


    border-radius: 8px;
}

.carousel-item-slider {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    aspect-ratio: 7/2;
    position: relative;
    border-radius: 8px;

}
.carousel-item-slider img{
    width: 100%;
    height: 100%;
}
.order-table{
    max-width: 100%;
    font-size: 13px;
    margin-top: 20px;
}
.group-img{
    width: 75px;
    height: 75px;
    border-radius: 50%;
    margin-left: 5px;
}

.marquee {
    background: var(--dark);
    color: var(--white);
    height: 40px;
    font-size: 20px;

}

.box-shadow {
    box-shadow: 4px 0px 29px -18px var(--dark);
}

.box-shadow {
    box-shadow: 4px 0px 29px -18px var(--dark);
}

.sidebar-width {
    width: min(399px, 80%) !important;
}

.flex-1 {
    flex: 1 0 auto !important;
}

.min-100-vh {
    min-height: 100vh;
}

/* Primary */
.bg-primary {
    background: var(--primary) !important;
}

.bg-primary-opacity {
    background: var(--primary-transparent) !important;
}

.text-primary {
    color: var(--primary) !important;
}

/* Secondary */
.bg-secondary {
    background: var(--secondary) !important;
}

.text-secondary {
    color: var(--secondary) !important;
}

/* Blue */
.bg-blue {
    background: var(--blue);
}

.text-blue {
    color: var(--blue);
}

/* Orange*/
.text-orange {
    color: var(--orang);
}

/* White */
.bg-white {
    background: var(--white);
}

.text-white {
    color: var(--white) !important;
}

/* Dark */
.bg-dark {
    background: var(--dark) !important;
}

.text-dark {
    color: var(--dark) !important;
}

.orange-btn {
    color: var(--white);
    background: var(--orang);
    border-radius: 20px;
    flex: 1 0 auto;
    border: 1px solid var(--dark);
    transition: all 1s;
}

.orange-btn:hover,.submit-btn:hover {
    color: var(--white);
    background: var(--dark);
    border: 1px solid var(--orang);

}

.dark-btn {
    color: var(--white);
    background: var(--dark);
    border-radius: 20px;
    flex: 1 0 auto;
    border: 1px solid var(--orang);
    transition: all 1s;
}
.dark-btn:hover,.submit-btn:hover {
    color: var(--white);
    background: var(--orang);
    border: 1px solid var(--dark);

}
.submit-btn{
    color: var(--white);
    background: var(--orang);
    border-radius: 20px;
    width: min(100%,250px);
    border: 1px solid var(--dark);
    transition: all 1s;
}
.section-wrapper{
    padding-bottom: 50px;
}
/* Marquee */
.marquee-container {
    width: 100%;
    overflow: hidden;
    white-space: nowrap;

}

.marquee-content {
    display: inline-block;
    animation: marquee-animation 20s linear infinite;
}

.category-item {
    height: 165px;
    background-size: cover;
    background-position: center;
    background-attachment: scroll;
    background-repeat: no-repeat;
    width: 100%;
    position: relative;
    transition: all 500ms;
    border-radius: 15px;
    overflow: hidden;

}
.background{
    height: 100%;
    background-size: cover;
    background-position: center;
    background-attachment: scroll;
    background-repeat: no-repeat;
    width: 100%;
    position: absolute;
    filter: blur(5px);
    border-radius: 15px;
    z-index: 0;
}
.category-item img{
    position: relative;
    width: 165px;
    height: 165px;
    border-radius: 15px;
    z-index: 2;

}

.category-item-overlay {
    position: absolute;
    background: var(--dark-transparent);
    height: 50px;
    width: 100%;
    top: calc(100% - 50px);
    backdrop-filter: blur(5px);
    right: 0;
    transition: all 500ms;
    z-index: 3;
}

.category-item:hover{
    /* border: 1px solid var(--orang);*/

}

.category-item:hover  .category-item-overlay{
    border-bottom-left-radius:15px ;
    border-bottom-right-radius:15px ;
    backdrop-filter: blur(0px);
    transform: scale(1.5);
    top: calc(100% - 55px);
}


.input-form{
    width:min(100%,250px);
    border-radius: 35px !important;
    background: var(--dark-transparent);
    color: var(--white) !important;
    text-indent: 7px;
    height: 30px;
    display: block;

}
.textarea-form{
    width:min(100%,250px);
    border-radius:15px !important;
    background: var(--dark-transparent);
    color: var(--white);
    text-indent: 7px;
    resize: none;


}

.card-auth{
    background: var(--dark-transparent);
    border-radius: 10px;
    padding: 20px 20px;
}
.logo-auth{
    display: inline-block;
    width: 150px;
    height: 150px;
    margin: auto 20px;
    border-radius: 50%;

}
/*Bootom Nav */
footer.bottom-nav{
    position: fixed;
    bottom: 0;
    background: var(--primary);
    height: 45px;
    width: 100%;
    margin-top: 50px;
    z-index: 10;
}
footer.bottom-nav a.active>i{
    color: var(--orang) !important;
}
/* Animations */


@keyframes marquee-animation {
    0% {
        transform: translateX(calc(-100vw));
    }
    100% {
        transform: translateX(calc(100vw + 100%));
    }
}
.checkName >svg{
    color: var(--white);
}
.checkName.loading > svg{
    animation: rotate-360 1s ease-in-out infinite;
}
@keyframes category-item-rotate {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(20deg);
    }
}
@keyframes rotate-360 {
    from{
        transform: rotate(0deg);
    }
    to{
        transform: rotate(360deg);
    }

}